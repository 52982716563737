.container {
  font-family: Nunito Sans;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}

.wrapper {
  border: 1px solid transparent;
  border-radius: 16px;
  background-color: #fff;
  padding: 2.5rem;
}

.formButton {
  width: 343px;
  height: 56px;
  font-family: Nunito Sans;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  border-radius: 2px;
  color: white;
  background: #338a87;
  border: 0;
  cursor: pointer;
  margin-top: 3rem;
}
