.container {
  display: flex;
  justify-content: center;
  flex: 1;
  height: 100%;
  padding: 0px 20px;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  margin: 0 0 2rem 0;
}

.loading {
  margin-top: 20px;
}

.form {
  font-family: Nunito Sans;
  font-style: normal;
  margin: 0;
  padding: 0;
  text-align: center;
}

.text {
  width: 311px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #a0a0a0;
  display: inline-block;
}

.formTitle {
  font-weight: bold;
  font-size: 27px;
  line-height: 32px;
  letter-spacing: 1px;
}

.statusText {
  color: #666;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 20px;

  a {
    display: inline;
    color: #338a87;
    &:hover,
    &:active,
    &:visited {
      color: #338a87;
    }
  }
}

.formInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 343px;
  height: 56px;
  font-size: 16px;
  line-height: 24px;
  padding-left: 10px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  background-color: #fff;
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
}

.formButton {
  width: 343px;
  height: 56px;
  font-family: Nunito Sans;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  border-radius: 2px;
  color: white;
  background: #338a87;
  border: 0;
  cursor: pointer;
}

.openAppText {
  padding-bottom: 3rem;
}
