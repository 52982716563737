.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Nunito Sans;
  padding: 1rem;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 1.2;
    text-align: center;
    color: #338a87;
    padding-bottom: 2rem;
  }

  .table {
    display: grid;
    background-color: #fff;
    padding: 2.5rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;

    grid-column-gap: 50px;
    grid-row-gap: 40px;
    margin-bottom: 4rem;

    justify-items: start;

    input[type='checkbox'] {
      justify-self: end;
    }
  }
}

.formButton {
  width: 343px;
  height: 56px;
  font-family: Nunito Sans;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  border-radius: 2px;
  color: white;
  background: #338a87;
  border: 0;
  cursor: pointer;
}

.status {
  color: #666;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 20px;

  a {
    color: #338a87;
    &:hover,
    &:active,
    &:visited {
      color: #338a87;
    }
  }
}

.loading {
  margin-top: 30px;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.logout {
  display: flex;
  margin-top: 20px;
  flex: 1;
  align-content: center;
  justify-content: center;
  color: #338a87;
  &:hover,
  &:active,
  &:visited {
    color: #338a87;
  }
}
