@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap);
.Home_container__2nNEp{display:flex;justify-content:center;flex:1 1;height:100%;padding:0px 20px}.Home_app__IRkSD{display:flex;flex-direction:column;justify-content:flex-start;align-items:center}.Home_logo__2oDJq{margin:0 0 2rem 0}.Home_loading__3dsj8{margin-top:20px}.Home_form__1qsqr{font-family:Nunito Sans;font-style:normal;margin:0;padding:0;text-align:center}.Home_text__3mHXp{width:311px;font-weight:normal;font-size:14px;line-height:16px;text-align:center;color:#a0a0a0;display:inline-block}.Home_formTitle__3eLwp{font-weight:bold;font-size:27px;line-height:32px;letter-spacing:1px}.Home_statusText__2Iwrq{color:#666;font-size:16px;line-height:24px;text-align:center;margin-bottom:20px;padding:0 20px}.Home_statusText__2Iwrq a{display:inline;color:#338a87}.Home_statusText__2Iwrq a:hover,.Home_statusText__2Iwrq a:active,.Home_statusText__2Iwrq a:visited{color:#338a87}.Home_formInput__25zj2{-webkit-appearance:none;-moz-appearance:none;appearance:none;width:343px;height:56px;font-size:16px;line-height:24px;padding-left:10px;border:1px solid #f0f0f0;border-radius:2px;background-color:#fff}.Home_formInput__25zj2::-webkit-input-placeholder{color:rgba(0,0,0,0.3)}.Home_formInput__25zj2:-ms-input-placeholder{color:rgba(0,0,0,0.3)}.Home_formInput__25zj2::-ms-input-placeholder{color:rgba(0,0,0,0.3)}.Home_formInput__25zj2::placeholder{color:rgba(0,0,0,0.3)}.Home_formButton__3ViCw{width:343px;height:56px;font-family:Nunito Sans;font-weight:bold;font-size:18px;line-height:24px;border-radius:2px;color:white;background:#338a87;border:0;cursor:pointer}.Home_openAppText__3qpm7{padding-bottom:3rem}

.Authenticated_container__2S8RS{font-family:Nunito Sans;display:flex;flex-direction:column;justify-content:center;align-items:center;background-color:#f8f8f8}.Authenticated_wrapper__1o5Gr{border:1px solid transparent;border-radius:16px;background-color:#fff;padding:2.5rem}.Authenticated_formButton__3ZVrX{width:343px;height:56px;font-family:Nunito Sans;font-weight:bold;font-size:18px;line-height:24px;border-radius:2px;color:white;background:#338a87;border:0;cursor:pointer;margin-top:3rem}

.Billing_container__33Re9{display:flex;flex-direction:column;justify-content:center;align-items:center;font-family:Nunito Sans;padding:1rem}.Billing_container__33Re9 h1{font-style:normal;font-weight:bold;font-size:27px;line-height:1.2;text-align:center;color:#338a87;padding-bottom:2rem}.Billing_container__33Re9 .Billing_table__2ZjlH{display:grid;background-color:#fff;padding:2.5rem;grid-template-columns:repeat(3, 1fr);grid-template-rows:auto;grid-column-gap:50px;grid-row-gap:40px;margin-bottom:4rem;justify-items:start}.Billing_container__33Re9 .Billing_table__2ZjlH input[type='checkbox']{justify-self:end}.Billing_formButton__TML28{width:343px;height:56px;font-family:Nunito Sans;font-weight:bold;font-size:18px;line-height:24px;border-radius:2px;color:white;background:#338a87;border:0;cursor:pointer}.Billing_status__16bR3{color:#666;font-size:16px;line-height:24px;text-align:center;margin-bottom:20px;padding:0 20px}.Billing_status__16bR3 a{color:#338a87}.Billing_status__16bR3 a:hover,.Billing_status__16bR3 a:active,.Billing_status__16bR3 a:visited{color:#338a87}.Billing_loading__2FDMK{margin-top:30px}.Billing_loadingContainer__1suY1{display:flex;flex-direction:column;align-content:center;align-items:center;justify-content:center}.Billing_logout__DsY0y{display:flex;margin-top:20px;flex:1 1;align-content:center;justify-content:center;color:#338a87}.Billing_logout__DsY0y:hover,.Billing_logout__DsY0y:active,.Billing_logout__DsY0y:visited{color:#338a87}

.SuccessModal_container__1MSL2{font-family:Nunito Sans;display:flex;flex-direction:column;justify-content:center;align-items:center}.SuccessModal_container__1MSL2 h1{font-size:27px;text-align:center}.SuccessModal_wrapper__28qJJ{border:1px solid transparent;border-radius:16px;background-color:#fff;padding:2.5rem}.SuccessModal_text__YhAoM{max-width:300px;text-align:center;font-size:16px;line-height:1.5}

body,html{font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#f8f8f8;height:100%;margin:0;padding:0}div#root{height:100%;margin:0;padding:0;padding-top:2rem}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

