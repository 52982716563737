.container {
  font-family: Nunito Sans;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 27px;
    text-align: center;
  }
}

.wrapper {
  border: 1px solid transparent;
  border-radius: 16px;
  background-color: #fff;
  padding: 2.5rem;
}

.text {
  max-width: 300px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
}
